

const allowList = [
    { "account": "0xBFcDaD988d3c804cE4497571916c5dc20d09A6C9", "alloc": 1 },
    { "account": "0x26Acf54595A3f438BAE962d471fe84ce6167ed89", "alloc": 1 },
    { "account": "0x684D5D53Ab4A73950d45B58175F242B77b81366E", "alloc": 1 },
    { "account": "0xA4DEDa1Ca25a088777e22fEFb4F55d976FC620f1", "alloc": 1 },
    { "account": "0xF0128F6c493F869F1ad347Ba351D782dD7dFeC68", "alloc": 1},
    { "account": "0xf7577e9bAf9a62C9380608ba7C864494e33c4F35", "alloc": 1},
    { "account": "0xC03B0fEDEA814E0D32738D21Cc2349FAE1affb4c", "alloc": 2},
    { "account": "0x2F302a579eEBd49d813f2F199846F793Bbd537B3", "alloc": 1},
    { "account": "0x894F45197d02f6a0a0FEb43664c3dc5e58979a31", "alloc": 1},
    { "account": "0xD7C590fF97A7dFE709229C98bBA5ccad63dFCfD7", "alloc": 2},
    { "account": "0x997156178b911DE1570FE44dA0B3239d877E793e", "alloc": 2},
    { "account": "0x670eEBB8097aeB8F5C9dfe630cf5AD8a04FFb8A5", "alloc": 3},
    { "account": "0x5DcC11ED6C8F030344891ED1388eF4687649De62", "alloc": 1},
    { "account": "0x879c8450A97dd309eD2967f6b0d0d1B1341f56f7", "alloc": 3},
    { "account": "0xBcCF1763974A8aE51fdD25ebD7c6481e0f81E8f2", "alloc": 5},
    { "account": "0x4ebF7b526f5c076f46130A22Bb00b3670B66C8Ea", "alloc": 1},
    { "account": "0x6Ef400d7edb8242be8B3cb9e03eEC51469840C6A", "alloc": 55},
    { "account": "0x069c2039f2aef978592A367a8F70F07A2Bb8eEC7", "alloc": 2},
    { "account": "0x853b9230d2a96661f0269aF4996B047c3b7F0b51", "alloc": 1},
    { "account": "0x84D00a1F358776Da0D36e93E99a16E37415E00dB", "alloc": 1},
    { "account": "0xB731288df2c0e57FF7517063837d2Cbd3bA6881A", "alloc": 1},
    { "account": "0xABBfC6686CdcDaf9a84c7F9396bD776Aa8591b7F", "alloc": 1},
    { "account": "0x3191B713076F9F438Da310899209Ca89e63f9151", "alloc": 2},
    { "account": "0x8DE1462e9d62363e19303F3bB2AbA506E34cA879", "alloc": 1},
    { "account": "0xB7A763542Ac325B7cFFa70529f3EbeA37036a2C7", "alloc": 1},
    { "account": "0x1aFe605d43C14D19B03b0739fbb88183A6A7b721", "alloc": 1},
    { "account": "0x1B2b43C9CB3581329c7A1CDdAC938920034BaC0C", "alloc": 1},
    { "account": "0x60215C5a77c61ddaa646b65A6b5623A3D129cb6F", "alloc": 2},
    { "account": "0xd0d1BBefA38252cd452EEDE65eaE3762386a5942", "alloc": 2},
    { "account": "0xD660DaB9FFEA88cDc70d976126102e56C7519c2c", "alloc": 5},
    { "account": "0x578630cde9366D21Ed9cFfBF9E5155Ce5C463e50", "alloc": 3},
    { "account": "0x1906578e7A3cB87d8ea96fcf36a17cD83775aF89", "alloc": 1},
    { "account": "0x66bDe825160eB8188FeC9Ae83423A38b9197abe4", "alloc": 1},
    { "account": "0xC4FA24aa8D66C6bac7798344d5Ce0EA19dC16173", "alloc": 1},
    { "account": "0x23DFf4A8C90fDDFcb9A2256Bcb73B5080D60a8aB", "alloc": 1},
    { "account": "0x50857637a24Cf3D9E4D27037551eB7623cF486e0", "alloc": 1},
    { "account": "0x376b40690bA3305A8Cd28EA5692E693E00D9B608", "alloc": 2},
    { "account": "0xCBa4FBFaD809b94184bFff39c1A33501b2bf99D1", "alloc": 1},
    { "account": "0x593183700c4A68cBA7136DaEc6FcC19FEB3d970f", "alloc": 1},
    { "account": "0xcD2ad0a3e32852feaAA96a6ca660B8D1C0a647Da", "alloc": 50},
    { "account": "0xbA3432D3986Cf56e1b6D66D7Fa6cfB7b9769Af21", "alloc": 50},
    { "account": "0xBE2B9a1868c26fB6e1bEb1f236056a26c61b128E", "alloc": 50},
    { "account": "0xa33a70FABFeb361Fe891C208B1c27ec0b64baBEB", "alloc": 5},
    { "account": "0x0aD2889b7659b6c18dEe8a7b4d331069b14F9aA0", "alloc": 5},
    { "account": "0x7685f62A2C062E929271De232FfC21e40eEc25b5", "alloc": 1},
    { "account": "0xa1e24d6D303053b2761c3fF98dB344BEa57b5454", "alloc": 1},
    { "account": "0x65Dbf06EdcD83C123b2da69d262250b3FB35c7fF", "alloc": 2},
    { "account": "0xF2Cd73e1400AF890adaa74E57EC91de8EF77Fa05", "alloc": 2},
    { "account": "0x4715CF79fDE6669ec1370119a992f52c189685E1", "alloc": 2},
    { "account": "0x18f10d787469ea2870ccA80d073a2b194E98Ad66", "alloc": 1},
    { "account": "0xd0F3d1F1c37d9A600E6850c041b1E7C4A92ca681", "alloc": 1},
    { "account": "0x5eC58c515dFde0A85978b19aF91B4042B648d27E", "alloc": 1},
    { "account": "0x7A8D0f104E0880206f27FE0F4Ae685061b7db454", "alloc": 1},
    { "account": "0xcFA3F38EAF93e86814E998a66AEce6D934DC46d2", "alloc": 1},
    { "account": "0xF8F62f4F6D49337A6b2605e21aD1569777DDC3bA", "alloc": 5},
    { "account": "0x98ed734835092A8681b4F8407234dE06A30dEBb8", "alloc": 2},
    { "account": "0x2cB1eA352BA5c3109c60a83521c16b8A5fdf5aF6", "alloc": 1},
    { "account": "0x79FAca935e3d46f963DF11EF837055DeCAaB52b0", "alloc": 1},
    { "account": "0xd74556B484E269c7224DC8cF2836e7860EE93946", "alloc": 1},
    { "account": "0xa1cd1424fcf929e2455e75c5762b0ca6146668f3", "alloc": 2},
    { "account": "0x0b1f8dc3e9c68d99ae3ebfbf797ddb37cc03f27c", "alloc": 1},
    { "account": "0x8426dab76c807F84b531d3F52831C343C77701a5", "alloc": 1},
    { "account": "0x27ae2a25961647db330eAf9755Dc639fE70E8c0A", "alloc": 1},
    { "account": "0x12D2b02FE482Ee1c62B2D2bdf8116ec7D122efD8", "alloc": 1},
    { "account": "0xC6D587794019Df25A92fC0b5Bf3a3908645CE353", "alloc": 1},
    { "account": "0xF2B9caa405c3D3cf86a8386d1a8812Fbd8177B11", "alloc": 1},
    { "account": "0x3072A1beAb6C5D1523CB04823b21fB61c1dfE702", "alloc": 1},
    { "account": "0x3ede04de6697760b88a413c3f183b7a5f3e781e3", "alloc": 1},
    { "account": "0xEcBcD040D1BFFF4aAB84ae1Fce3d527b8Ca0Eed3", "alloc": 1},
    { "account": "0x9f57d528F01c1a2fe95E13Fef05E273cF18115bE", "alloc": 1},
    { "account": "0x29b2352266400787f82A06E4bcacD7E9c6443b43", "alloc": 1},
    { "account": "0x997156178b911DE1570FE44dA0B3239d877E793e", "alloc": 1},
    { "account": "0x6DE35c38d632Ff6611379574753cFAfbc6E6Fa41", "alloc": 1},
    { "account": "0x857d86cbBA0dac75b481a971173f4f6708420C20", "alloc": 1},
    { "account": "0x5C10019aF4a397F16F83d4a566C9ad5b9F938a9E", "alloc": 1},
    { "account": "0x3F7D5b2a7f40EF1Bb3d4C1Fcceb5950538c62Ab2", "alloc": 2},
    { "account": "0xc07f63338050b5fc7966C007D2e310f01b895c2F", "alloc": 3},
    { "account": "0x49d34f28FDcFa010856340CE2f9D38203B09a939", "alloc": 1},
    { "account": "0x01785cf382ce13096b4ddd98a715a76140be11c7", "alloc": 1},
    { "account": "0x6BF4599ee6d6B05a8FA1E0cB9E1179db6bAE25fa", "alloc": 1},
    { "account": "0x23Dd6fc558a1989b1bD2be030D635f2308664C48", "alloc": 1},
    { "account": "0x23aa18bb1e81d591882bfe26a554ced5a0e33083", "alloc": 5},
    { "account": "0xABBfC6686CdcDaf9a84c7F9396bD776Aa8591b7F", "alloc": 1},
    { "account": "0xCf11d957D94A7d2988d9f9b875EAFBB1A2D765D1", "alloc": 1},
    { "account": "0x1B2b43C9CB3581329c7A1CDdAC938920034BaC0C", "alloc": 2},
    { "account": "0x896365f11B2B85D986E5540EcB8Df01B030e5988", "alloc": 1},
    { "account": "0x5d95F130C99991AD03472e89f5738B9Ea3c7e62a", "alloc": 1},
    { "account": "0xC9D9ea3Ae5DF3Ef1E0FE55DD12715fA5541Ba272", "alloc": 1},
    { "account": "0x6C4Bb1A7d76e3AF4144Ee9A645666Bfb150b9A6a", "alloc": 1},
    { "account": "0x3b5178bdF83E843698BB88BcAc7764e49318CE93", "alloc": 1},
    { "account": "0xea513540E8E0c37bb83BA2469DDb21df96B4738F", "alloc": 1},
    { "account": "0x2A6C720Ec0E8B0563a2c9AfD65FFec0aa5E4FF4D", "alloc": 1},
    { "account": "0x0909fb294fA8274b24618fe5DDE1C5D409001f9D", "alloc": 5},
    { "account": "0x2a1FB2063Eb18e1D5418A1f205E866047979BD6A", "alloc": 1},
    { "account": "0x15E699c8951d7815BEEBc45b0Aa714E8666b608b", "alloc": 2},
    { "account": "0x3985543993fd7582d0A9Aea2D328AF497bFB631C", "alloc": 3},
    { "account": "0x84b3ca8344f3f059958694E18D361D3E148D3aB8", "alloc": 1},
    { "account": "0x21e52789ebdcAf5F7379298f3A4F540139B0E43F", "alloc": 10},
    { "account": "0x5B0fee53BeC38BC2Aa2a87D0C7e0Da0eb9f50eCf", "alloc": 1},
    { "account": "0x829Db0F1Fb6696327d245F953c7e4f89b6294FDC", "alloc": 30},
    { "account": "0xf86c10903C37972975Ed97EBdB4718c09CB1F367", "alloc": 3},
    { "account": "0x7Cbf2128c7c57e7Ff0c3dF3c39838431f4eb50c3", "alloc": 2},
    { "account": "0xF6b6E9366840e4CF8214c50F8A84a9Ec10F1Fd2E", "alloc": 1},
    { "account": "0x85750B5D8606ca5038B681541429C722C882cd53", "alloc": 1},
    { "account": "0xF050A45B14e003c95C3dc00C8ab4e20E9E465683", "alloc": 2},
    { "account": "0x66856A1a2400da54506833Bea3af20cB24D3B867", "alloc": 25},
    { "account": "0xD56c08a07f222A10385E7a9dDfA7473050748f98", "alloc": 20},
    { "account": "0x57Bb59842e6A9d0600453A20D61DD291EE0a6Aa0", "alloc": 2},
    { "account": "0xdEB7be28ffBff4a16744b527650cE9FA0b1F4f8f", "alloc": 4},
    { "account": "0x0813398b906d9Ef9A38Cfd92672932a7A3fe451B", "alloc": 1},
    { "account": "0xCF120C5d0f967cd2E1D38f337e3594885456CfA9", "alloc": 25},
    { "account": "0xba7B732a49Ae70d855099E94099C938246695921", "alloc": 10},
    { "account": "0xdDD5035de569Ef502238D0F39e0f7108884C8a27", "alloc": 1},
    { "account": "0xe2F20718eB67610B2E55d04F4B8A4cDB372eE049", "alloc": 3},
    { "account": "0x27F565005d9e4A5A993bDDB20Ec62ac770bCE600", "alloc": 1},
    { "account": "0x492FDcdE2e83b2549d8aADDCf886C6a1aDdbb8F7", "alloc": 4},
    { "account": "0xdd65d5FF929a3a3a898F41Ea70230645D6D8751f", "alloc": 5},
    { "account": "0x1C065b2B4BE6102e5d8E99E99957a9b5a2375424", "alloc": 1},
    { "account": "0xf58E1c032d7579B43f43fE113a0419D72DD19CC5", "alloc": 1},
    { "account": "0x49702908f58AF3aDb64ac31F7B4a9fE57389FCC8", "alloc": 30},
    { "account": "0x0741ee26bfEE69F7304E1bDeEF1C09125bBfD4a7", "alloc": 9},
    { "account": "0xE67E795fE8b2b2bD30A09CFCCDDa4a8612AAd818", "alloc": 23},
    { "account": "0x4E0aeF904cABCebd26426D53071721cFa80e9Fae", "alloc": 4},
    { "account": "0xea27544ee11Ed7856E376adbd4E359D66471b591", "alloc": 20},
    { "account": "0xf749ABC0eA404bC7D9775279527d086376471b4D", "alloc": 1},
    { "account": "0x844BFDB072B1Ea4FA7277C6dA8C73ae1eB0fB7F9", "alloc": 1},
    { "account": "0x6bD656cc47F1670Ed2C945AF2216a7b2B7285a4f", "alloc": 1},
    { "account": "0x0538732BAEe1525A8C2c20f0bC430C732A1cf455", "alloc": 40},
    { "account": "0x8b3AE16B4c73b6d7FdaDB806453F1560c0fb5281", "alloc": 2},
    { "account": "0xF8EAF5847880D540Deed8fCED81bcc2E563a7CF9", "alloc": 1},
    { "account": "0x4d346B9cfD86CA8DB712FeB0AcdD4893AA063ae3", "alloc": 2},
    { "account": "0x541893b35d6cff9a3c2bff28362173325adf84e9", "alloc": 1},
    { "account": "0xCBCdE1483ff1Bb6F6787E0c2025A5eCc5AC6a66B", "alloc": 1},
    { "account": "0xCe9af34850ECD47870a6d64BF1eFCc91cf850403", "alloc": 1},
    { "account": "0xEB9fFB8ee6C5AB7567712E7b866c708A79bb0222", "alloc": 1},
    { "account": "0xcC57A569E8cCed03A1cD9206bb35C54255Dfc452", "alloc": 1},
    { "account": "0x53199C5fD097e44dccA4320846ac74fe9dFd4b9C", "alloc": 1},
    { "account": "0x2824098d970f737c0234a17D70b1580c62e7371e", "alloc": 1},
    { "account": "0xcf8F716348f84165B410850ea859A8fCd2FBb772", "alloc": 1},
    { "account": "0xCe5e1a0C6C3e11F33bd981dD87a44280da10FE66", "alloc": 1},
    { "account": "0xb9adFf419af496D478Fa88A4D7861BB8181CB799", "alloc": 1},
    { "account": "0x15BFbA7f1ADDdeABb6Ad7f656cbA62d53D3FcB83", "alloc": 1},
    { "account": "0xCcf5d0fF56e420cEDA2A234D67d3E329B238aceF", "alloc": 1},
    { "account": "0xba41a3B71560EF120498f6f1b87ab273f6f737BE", "alloc": 1},
    { "account": "0x4e8D0422668e835a92de54C4A263bF1f1ed235Ed", "alloc": 1},
    { "account": "0xF0128F6c493F869F1ad347Ba351D782dD7dFeC68", "alloc": 1},
    { "account": "0xAD9Df2dE9EE85d7F87c593384eD2D4Da58Aef605", "alloc": 1},
    { "account": "0x3e909Fa113DC18943031741e391f9192D8e8FC03", "alloc": 1},
    { "account": "0xDda5a168C69f9fFd9F9702448c032d6b474A51cd", "alloc": 1},
    { "account": "0xC8D51918614e1F7161F9B03C4c2eED1bD08C763f", "alloc": 1},
    { "account": "0xd10B89CfC3981F28e5aB6b513322019C1ba734C0", "alloc": 1},
    { "account": "0xf7B3B39B3EEf7e7fC3D8bd0a978c833EbC62d9F6", "alloc": 2},
    { "account": "0x1C832A8DD77CeE60d38d46F44535629962374F50", "alloc": 1},
    { "account": "0x107c9FF3a05D9748eA3B1cD4aDFF8987B00DA8a0", "alloc": 1},
    { "account": "0xEA29644E3bD7b60a98F0555da259D5ef0aA6f90D", "alloc": 1},
    { "account": "0xc93768E4654D8cACE16e22f63a0a369ab3EDf22B", "alloc": 3},
    { "account": "0xF4Ec2524891fCa4733374EE84fDb6b9A6b6b09Eb", "alloc": 1},
    { "account": "0x7c936b27286a734A74794DA454Ec4AcABC7f2031", "alloc": 1},
    { "account": "0x2CACfe491A4d30b89459522cC4ce19d3926c758a", "alloc": 1},
    { "account": "0xEa41f8D34572EEEA13f6eFD9c44bE9F50610e0d7", "alloc": 1},
    { "account": "0x3E159451BFB6E6F0412835844C9E383BCfea7242", "alloc": 1},
    { "account": "0x1D1082b427dBcf9C902AC8817E46131F4cd08bc4", "alloc": 1},
    { "account": "0xE11Cc38467dC8b9fb649b04C0C60233ab7b7294C", "alloc": 1},
    { "account": "0x23EB1Cd03c6C5C57967A805A3dB9DBfDBa79F35b", "alloc": 1},
    { "account": "0x6d817BA182B7C43e34c22289F131cAB8e59e892D", "alloc": 1},
    { "account": "0xCf1DDB56f647f75Bc503bad161a397D51fB18C7A", "alloc": 3},
    { "account": "0xa6eBEB28Ef2F52B0af056793d273F39e15ED8d1f", "alloc": 2},
    { "account": "0x1149be2E878749E03244BE9834cf332Bce5310F8", "alloc": 1},
    { "account": "0x34F5C6c45B6632fF57Eb20A54532e6F1539F9B73", "alloc": 1},
    { "account": "0xC05AE67D2e01053A24065a2846327534A9542B91", "alloc": 1},
    { "account": "0xCb4BC5776997dEfEc1F227F251f54253f7D7229D", "alloc": 1},
    { "account": "0x857E03Bcc02b99F2C86C4D3d37b7Dd861A35E270", "alloc": 20},
    { "account": "0x4e65eFC260caf9d011966C8Cf749902404305B03", "alloc": 5},
    { "account": "0xaDFa3ab5d0Bee08b2340B4B192ff848AbC0dC6d5", "alloc": 5},
    { "account": "0xb5e5c70C3521d25F9c8B0CcAcd5A3DE4B32Ec429", "alloc": 5},
    { "account": "0x0181Ff9bbEc1c954DFb40d89A5c29595413309ff", "alloc": 2},
    { "account": "0x11e0B87Ab0418926D99F11f433602DE4041CE9c2", "alloc": 1},
    { "account": "0x42a80c793493C48e2594B535a6cEE2d04318FE46", "alloc": 6},
    { "account": "0x6FF147c19C771eC073b4a2FF920dfF3ab10aC19F", "alloc": 2},
    { "account": "0x23f598a92a140489609ee3eAcb7Cd25A22D671F7", "alloc": 1},
  ];

  
  module.exports = allowList;

  // 0xb5e5c70C3521d25F9c8B0CcAcd5A3DE4B32Ec429,10
  // 0xcD2ad0a3e32852feaAA96a6ca660B8D1C0a647Da,5
  // 0xbA3432D3986Cf56e1b6D66D7Fa6cfB7b9769Af21,15
  // 0x90d492c2EE2c76dFCf24Dd97Ca8B61F4659762b6,3
  // 0xa33a70FABFeb361Fe891C208B1c27ec0b64baBEB,4


  // const allowList = [
  //   { "account": "0xb5e5c70C3521d25F9c8B0CcAcd5A3DE4B32Ec429", "alloc": 10 },
  //   { "account": "0xcD2ad0a3e32852feaAA96a6ca660B8D1C0a647Da", "alloc": 5 },
  //   { "account": "0xbA3432D3986Cf56e1b6D66D7Fa6cfB7b9769Af21", "alloc": 15 },
  //   { "account": "0x90d492c2EE2c76dFCf24Dd97Ca8B61F4659762b6", "alloc": 3 },
  //   { "account": "0xa33a70FABFeb361Fe891C208B1c27ec0b64baBEB", "alloc": 4}
  // ];