import React from 'react'

function DebitCard() {
    return (
        <div className='debit-card'>
            
            <div className='frame-89'>
            <img src="/assets/gifs/debit-card-2.gif" alt="payment-card" />
                <img className='polygon-6' src="/assets/images/DebitCard/Polygon-6.png" alt=""/>
                <img className='frame-96-dots' src="/assets/images/DebitCard/Frame-96.png" alt=""/>
                <div className='ellipse-37'></div>
                
  
                <div className='ellipse-38'></div>
            </div>
        </div>
    )
}

export default DebitCard